/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_l0dv0_18rr4_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_header_l0dv0_18rr4_107:not(#\9) {
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-panel-nav-left-o5fvw3, 28px);
  padding-right: calc(var(--space-scaled-xxl-pfgv96, 32px) + var(--space-xl-a39hup, 24px));
}

.awsui_header-link_l0dv0_18rr4_112:not(#\9) {
  font-size: var(--font-panel-header-size-0vdgjc, 20px);
  line-height: var(--font-panel-header-line-height-x2226j, 24px);
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
  color: var(--color-text-heading-default-jopz0f, #000716);
  min-height: var(--font-panel-header-line-height-x2226j, 24px);
  display: flex;
}
.awsui_header-link--has-logo_l0dv0_18rr4_120 > .awsui_header-link-text_l0dv0_18rr4_120:not(#\9) {
  font-weight: 400;
}

.awsui_header-logo_l0dv0_18rr4_124:not(#\9) {
  margin-right: var(--space-s-hv8c1d, 12px);
  margin-top: var(--space-xxxs-k2w98v, 2px);
  max-width: calc(1.25 * var(--size-icon-big-cu55wt, 32px));
  align-self: flex-start;
}
.awsui_header-logo--stretched_l0dv0_18rr4_130:not(#\9) {
  max-width: 100%;
  margin-right: 0;
}

.awsui_list-container_l0dv0_18rr4_135:not(#\9) {
  margin-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_list_l0dv0_18rr4_135:not(#\9) {
  margin: 0;
  padding: 0;
  padding-left: var(--space-l-4vl6xu, 20px);
}

.awsui_list-variant-root_l0dv0_18rr4_145:not(#\9) {
  margin: 0;
  margin-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
  padding: 0 var(--space-panel-side-right-kev4k9, 24px) 0 var(--space-panel-nav-left-o5fvw3, 28px);
}

.awsui_list-variant-expandable-link-group_l0dv0_18rr4_151:not(#\9) {
  padding-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_list-item_l0dv0_18rr4_155:not(#\9) {
  margin: var(--space-scaled-xs-6859qs, 8px) 0;
  padding: 0;
  list-style: none;
}

.awsui_section_l0dv0_18rr4_161:not(#\9),
.awsui_expandable-link-group_l0dv0_18rr4_162:not(#\9) {
  margin-left: calc(-1 * var(--space-l-4vl6xu, 20px));
}

.awsui_section_l0dv0_18rr4_161:not(#\9) {
  margin-top: calc(var(--space-scaled-2x-l-yejodm, 20px) - var(--border-divider-section-width-4wm2it, 2px));
  margin-bottom: calc(var(--space-scaled-2x-l-yejodm, 20px) - var(--border-divider-section-width-4wm2it, 2px));
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_section_l0dv0_18rr4_161 > div:not(#\9) {
  padding: 0;
}

.awsui_section-header_l0dv0_18rr4_175:not(#\9) {
  color: var(--color-text-body-secondary-fdstdf, #414d5c);
  font-weight: 700;
}

.awsui_link_l0dv0_18rr4_180:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-secondary-fdstdf, #414d5c);
  font-weight: 400;
}

.awsui_link-active_l0dv0_18rr4_187:not(#\9) {
  font-weight: var(--font-wayfinding-link-active-weight-l3fid7, 800);
  color: var(--color-text-accent-qpicvb, #F07C00);
}

.awsui_header-link_l0dv0_18rr4_112:not(#\9),
.awsui_link_l0dv0_18rr4_180:not(#\9) {
  text-decoration: none;
}
.awsui_header-link_l0dv0_18rr4_112:not(#\9):hover,
.awsui_link_l0dv0_18rr4_180:not(#\9):hover {
  color: var(--color-text-accent-qpicvb, #F07C00);
}
.awsui_header-link_l0dv0_18rr4_112:not(#\9):focus,
.awsui_link_l0dv0_18rr4_180:not(#\9):focus {
  outline: none;
}
.awsui_header-link_l0dv0_18rr4_112:not(#\9):hover, .awsui_header-link_l0dv0_18rr4_112:not(#\9):focus, .awsui_link_l0dv0_18rr4_180:not(#\9):hover, .awsui_link_l0dv0_18rr4_180:not(#\9):focus {
  text-decoration: none;
}
.awsui_header-link_l0dv0_18rr4_112[data-awsui-focus-visible=true]:not(#\9):focus,
.awsui_link_l0dv0_18rr4_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-k2wccv, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-nv4ahb, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_info_l0dv0_18rr4_219:not(#\9) {
  margin-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_external-icon_l0dv0_18rr4_223:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_divider_l0dv0_18rr4_227:not(#\9) {
  border: none;
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_divider-default_l0dv0_18rr4_232:not(#\9) {
  margin: var(--space-scaled-2x-xl-x30c9w, 24px) calc(-1 * var(--space-s-hv8c1d, 12px));
}

.awsui_divider-header_l0dv0_18rr4_236:not(#\9) {
  margin-top: 0;
}