/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189:not(#\9) {
  transition-duration: var(--motion-duration-refresh-only-fast-addxjd, 115ms);
  transition-property: none;
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189:not(#\9), .awsui-mode-entering .awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189.awsui_animating_1r9lg_8m9sl_203:not(#\9) {
  transition-property: transform;
  transition-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189.awsui_animating_1r9lg_8m9sl_203:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189.awsui_animating_1r9lg_8m9sl_203:not(#\9), .awsui-mode-entering .awsui_drawer_1r9lg_8m9sl_189.awsui_refresh_1r9lg_8m9sl_189.awsui_animating_1r9lg_8m9sl_203:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_preference-icon--layout-border_1r9lg_8m9sl_226:not(#\9) {
  stroke: var(--color-background-home-header-mpq08f, #000716);
  fill: var(--color-background-container-content-i8i4a0, #ffffff);
}
.awsui_preference-icon--layout-background_1r9lg_8m9sl_230:not(#\9) {
  fill: var(--color-background-layout-main-sfhm4y, #ffffff);
}
.awsui_preference-icon--layout-header_1r9lg_8m9sl_233:not(#\9) {
  fill: var(--color-background-home-header-mpq08f, #000716);
}
.awsui_preference-icon--border_1r9lg_8m9sl_236:not(#\9) {
  stroke: var(--color-text-group-label-w9to2i, #414d5c);
  fill: var(--color-background-container-content-i8i4a0, #ffffff);
}
.awsui_preference-icon--primary-button_1r9lg_8m9sl_240:not(#\9) {
  fill: var(--color-background-button-primary-default-m2j9xt, #F07C00);
}
.awsui_preference-icon--secondary_1r9lg_8m9sl_243:not(#\9) {
  fill: var(--color-text-group-label-w9to2i, #414d5c);
}
.awsui_preference-icon--disabled-element_1r9lg_8m9sl_246:not(#\9) {
  fill: var(--color-background-control-disabled-zplipy, #d1d5db);
}
.awsui_preference-icon--separator_1r9lg_8m9sl_249:not(#\9) {
  stroke: var(--color-background-control-disabled-zplipy, #d1d5db);
}
.awsui_preference-icon--focus-text_1r9lg_8m9sl_252:not(#\9) {
  fill: var(--color-text-interactive-default-eg5fsa, #414d5c);
}

.awsui_preference-icon-refresh--layout-top_1r9lg_8m9sl_256:not(#\9) {
  fill: var(--color-background-button-primary-disabled-emdb75, #ac7031);
}
.awsui_preference-icon-refresh--layout-main_1r9lg_8m9sl_259:not(#\9) {
  fill: var(--color-background-layout-main-sfhm4y, #ffffff);
}
.awsui_preference-icon-refresh--primary_1r9lg_8m9sl_262:not(#\9) {
  fill: var(--color-background-button-primary-default-m2j9xt, #F07C00);
}
.awsui_preference-icon-refresh--disabled_1r9lg_8m9sl_265:not(#\9) {
  fill: var(--color-background-control-disabled-zplipy, #d1d5db);
}
.awsui_preference-icon-refresh--column-header_1r9lg_8m9sl_268:not(#\9) {
  fill: var(--color-text-input-disabled-oawyzo, #9ba7b6);
}
.awsui_preference-icon-refresh--window_1r9lg_8m9sl_271:not(#\9) {
  stroke: var(--color-background-home-header-mpq08f, #000716);
  fill: var(--color-background-container-content-i8i4a0, #ffffff);
}
.awsui_preference-icon-refresh--secondary_1r9lg_8m9sl_275:not(#\9) {
  fill: var(--color-text-body-secondary-fdstdf, #414d5c);
}
.awsui_preference-icon-refresh--default_1r9lg_8m9sl_278:not(#\9) {
  fill: var(--color-text-body-default-ajf1h5, #000716);
}
.awsui_preference-icon-refresh--separator_1r9lg_8m9sl_281:not(#\9) {
  stroke: var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_preference-icon-refresh--input-default_1r9lg_8m9sl_284:not(#\9) {
  fill: var(--color-border-input-default-e47bkv, #7d8998);
}
.awsui_preference-icon-refresh--heading_1r9lg_8m9sl_287:not(#\9) {
  fill: var(--color-text-heading-default-jopz0f, #000716);
}

/* stylelint-disable selector-combinator-disallowed-list, selector-max-type, selector-max-universal */
.awsui_resize-active_1r9lg_8m9sl_292 *:not(#\9) {
  -webkit-user-select: none;
          user-select: none;
}
.awsui_resize-active_1r9lg_8m9sl_292 iframe:not(#\9) {
  pointer-events: none;
}
.awsui_resize-side_1r9lg_8m9sl_298 *:not(#\9) {
  cursor: ew-resize;
}
.awsui_resize-bottom_1r9lg_8m9sl_301 *:not(#\9) {
  cursor: ns-resize;
}

/* stylelint-enable */
.awsui_root_1r9lg_8m9sl_306:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
}

.awsui_preferences-button_1r9lg_8m9sl_317:not(#\9) {
  /* used in test-utils */
}

.awsui_close-button_1r9lg_8m9sl_321:not(#\9) {
  /* used in test-utils */
}

.awsui_drawer_1r9lg_8m9sl_189:not(#\9) {
  flex-shrink: 0;
  position: relative;
  word-wrap: break-word;
  background-color: var(--color-background-layout-panel-content-808qum, #ffffff);
  z-index: 851;
}
.awsui_drawer-closed_1r9lg_8m9sl_332:not(#\9) {
  cursor: pointer;
  min-width: 40px;
}

.awsui_drawer-content-side_1r9lg_8m9sl_337:not(#\9) {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: auto;
}
.awsui_drawer-closed_1r9lg_8m9sl_332 > .awsui_drawer-content-side_1r9lg_8m9sl_337:not(#\9) {
  width: 40px;
}
.awsui_drawer-closed_1r9lg_8m9sl_332 > .awsui_drawer-content-side_1r9lg_8m9sl_337:not(#\9):hover {
  background: var(--color-background-layout-panel-hover-0bg3gq, #e9ebed);
}
.awsui_drawer-content-side_1r9lg_8m9sl_337.awsui_refresh_1r9lg_8m9sl_189:not(#\9) {
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-panel-side-yjkfcr, #e9ebed);
  box-sizing: border-box;
}
.awsui_drawer-content-side_1r9lg_8m9sl_337 > [aria-hidden=true]:not(#\9) {
  display: none;
}

:not(#\9):not(.awsui_drawer-closed_1r9lg_8m9sl_332) > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 {
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.awsui_position-bottom_1r9lg_8m9sl_366:not(#\9) {
  position: fixed;
  overflow-y: auto;
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-panel-bottom-zrjpck, #e9ebed);
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-panel-bottom-zrjpck, #e9ebed);
  border-top: var(--border-panel-top-width-00mwcl, 1px) solid var(--color-border-divider-panel-bottom-zrjpck, #e9ebed);
  /*
  Removed the position fixed with the AppLayout refactor because the 
  SplitPanel is no longer in fixed position in the DOM.
  */
}
.awsui_position-bottom_1r9lg_8m9sl_366:not(#\9):not(.awsui_refresh_1r9lg_8m9sl_189) {
  box-shadow: var(--shadow-split-bottom-otfetg, 0px -36px 36px -36px rgba(0, 7, 22, 0.1));
}
.awsui_position-bottom_1r9lg_8m9sl_366.awsui_drawer-closed_1r9lg_8m9sl_332:not(#\9) {
  overflow: hidden;
}
.awsui_position-bottom_1r9lg_8m9sl_366.awsui_drawer-closed_1r9lg_8m9sl_332:not(#\9):hover {
  background: var(--color-background-layout-panel-hover-0bg3gq, #e9ebed);
}
.awsui_position-bottom_1r9lg_8m9sl_366 > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 > [aria-hidden=true]:not(#\9) {
  display: none;
}
.awsui_position-bottom_1r9lg_8m9sl_366.awsui_refresh_1r9lg_8m9sl_189:not(#\9) {
  position: relative;
}

.awsui_position-side_1r9lg_8m9sl_393:not(#\9) {
  border-top: none;
  box-shadow: var(--shadow-split-side-qv353u, -1px 0px 1px 0px #e9ebed, -36px 6px 36px -36px rgba(0, 7, 22, 0.1));
  height: 100%;
}

.awsui_slider_1r9lg_8m9sl_399:not(#\9) {
  padding: 0;
  cursor: ns-resize;
  margin: 2px 0 0 0;
  height: 16px;
  touch-action: none;
}
.awsui_slider_1r9lg_8m9sl_399:not(#\9):focus {
  outline: none;
}
.awsui_slider_1r9lg_8m9sl_399[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_slider_1r9lg_8m9sl_399[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
}
.awsui_slider_1r9lg_8m9sl_399[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  top: calc(-1 * 0px);
  width: calc(100% + 2 * 0px);
  height: calc(100% + 2 * 0px);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_slider-side_1r9lg_8m9sl_428:not(#\9) {
  cursor: ew-resize;
  margin: 0 0 0 2px;
}

.awsui_slider-wrapper-bottom_1r9lg_8m9sl_433:not(#\9) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.awsui_slider-wrapper-side_1r9lg_8m9sl_444:not(#\9) {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 18px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.awsui_open-button_1r9lg_8m9sl_455:not(#\9) {
  /* used in test-utils */
}

.awsui_open-button-side_1r9lg_8m9sl_459:not(#\9) {
  flex: 0 0 auto;
  align-self: flex-start;
  box-sizing: border-box;
  margin: var(--space-xxs-ynfts5, 4px) auto 0;
}

.awsui_slider-icon_1r9lg_8m9sl_466:not(#\9) {
  stroke: var(--color-text-interactive-default-eg5fsa, #414d5c);
}
.awsui_slider-icon_1r9lg_8m9sl_466:not(#\9):hover {
  stroke: var(--color-text-interactive-hover-3c98wn, #F07C00);
}
.awsui_slider-icon-bottom_1r9lg_8m9sl_472:not(#\9) {
  margin: auto;
}
.awsui_slider-icon-side_1r9lg_8m9sl_475:not(#\9) {
  transform: rotate(90deg);
}

.awsui_pane-header-wrapper-bottom_1r9lg_8m9sl_479:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  padding: 0 var(--space-scaled-2x-xxxl-lbp35j, 40px);
}
.awsui_drawer-mobile_1r9lg_8m9sl_489 > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 > .awsui_pane-header-wrapper-bottom_1r9lg_8m9sl_479:not(#\9) {
  padding: 0 var(--space-l-4vl6xu, 20px);
}
.awsui_drawer-disable-content-paddings_1r9lg_8m9sl_492 > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 > .awsui_pane-header-wrapper-bottom_1r9lg_8m9sl_479:not(#\9) {
  padding: 0 var(--space-l-4vl6xu, 20px);
}
:not(#\9):not(.awsui_drawer-closed_1r9lg_8m9sl_332) > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 > .awsui_pane-header-wrapper-bottom_1r9lg_8m9sl_479 {
  background-color: var(--color-background-layout-panel-content-808qum, #ffffff);
  border-bottom: var(--border-panel-header-width-6fmoo4, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_content-bottom_1r9lg_8m9sl_500:not(#\9) {
  padding: 0 var(--space-scaled-2x-xxxl-lbp35j, 40px);
  margin-top: var(--space-panel-split-top-0y4u4f, 20px);
}
.awsui_drawer-mobile_1r9lg_8m9sl_489 > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 > .awsui_content-bottom_1r9lg_8m9sl_500:not(#\9) {
  padding: 0 var(--space-l-4vl6xu, 20px);
}
.awsui_drawer-disable-content-paddings_1r9lg_8m9sl_492 > .awsui_drawer-content-bottom_1r9lg_8m9sl_357 > .awsui_content-bottom_1r9lg_8m9sl_500:not(#\9) {
  padding: 0;
}

.awsui_pane-bottom-center-align_1r9lg_8m9sl_511:not(#\9) {
  display: flex;
  justify-content: center;
}

.awsui_pane-bottom-content-nav-padding_1r9lg_8m9sl_516:not(#\9) {
  padding-left: calc(var(--space-layout-toggle-diameter-hzvqtj, 40px) + 2 * var(--space-layout-toggle-padding-axl9jr, 20px));
}

.awsui_pane-bottom-content-tools-padding_1r9lg_8m9sl_520:not(#\9) {
  padding-right: calc(var(--space-layout-toggle-diameter-hzvqtj, 40px) + 2 * var(--space-layout-toggle-padding-axl9jr, 20px));
}

.awsui_content-bottom-max-width_1r9lg_8m9sl_524:not(#\9) {
  flex-grow: 1;
  max-width: 100%;
}

.awsui_content-side_1r9lg_8m9sl_529:not(#\9) {
  flex: auto;
  align-self: flex-start;
  box-sizing: border-box;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}
.awsui_content-side_1r9lg_8m9sl_529 > .awsui_pane-header-wrapper-side_1r9lg_8m9sl_540:not(#\9) {
  padding: 0 var(--space-m-17eucw, 16px) 0 var(--space-panel-side-left-8ozqve, 28px);
}
.awsui_content-side_1r9lg_8m9sl_529 > .awsui_pane-content-wrapper-side_1r9lg_8m9sl_543:not(#\9) {
  padding: 0 var(--space-panel-side-right-kev4k9, 24px) 0 var(--space-panel-side-left-8ozqve, 28px);
  margin-top: var(--space-panel-split-top-0y4u4f, 20px);
}

.awsui_header_1r9lg_8m9sl_548:not(#\9) {
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: var(--space-scaled-m-pv0fmt, 16px) 0 var(--space-m-17eucw, 16px) 0;
}
.awsui_header-text_1r9lg_8m9sl_557:not(#\9) {
  font-size: var(--font-panel-header-size-0vdgjc, 20px);
  line-height: var(--font-panel-header-line-height-x2226j, 24px);
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
  padding: var(--space-xxs-ynfts5, 4px) 0 var(--space-scaled-xxs-95dhkm, 4px) 0;
  margin: 0;
}

.awsui_header-divider_1r9lg_8m9sl_565:not(#\9) {
  border: none;
  border-top: var(--border-panel-header-width-6fmoo4, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
  margin-top: calc(-1 * var(--border-panel-header-width-6fmoo4, 2px));
}

.awsui_header-actions_1r9lg_8m9sl_571:not(#\9) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;
  margin-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_divider_1r9lg_8m9sl_579:not(#\9) {
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
  margin: var(--space-scaled-xxs-95dhkm, 4px) var(--space-scaled-xs-6859qs, 8px);
}