/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
div.awsui_column-layout_vj6p7_gvt2c_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap: break-word;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104:not(#\9) {
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
  /* stylelint-disable-next-line selector-max-universal */
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-no-gutters_vj6p7_gvt2c_109:not(#\9) {
  margin: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
  margin: calc(-1 * (var(--space-text-grid-vertical-tj8m1c, 16px))) calc(-1 * var(--space-grid-gutter-0subky, 20px));
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112 > *:not(#\9) {
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-breakpoint-default_vj6p7_gvt2c_119 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-1_vj6p7_gvt2c_122.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-1_vj6p7_gvt2c_122.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-2_vj6p7_gvt2c_128.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-2_vj6p7_gvt2c_128.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(3n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-variant-text-grid_vj6p7_gvt2c_112.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(4n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104:not(#\9):not(.awsui_grid-no-gutters_vj6p7_gvt2c_109) > * {
  padding: calc(var(--space-grid-gutter-0subky, 20px) / 2);
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104:not(#\9):not(.awsui_grid-no-gutters_vj6p7_gvt2c_109).awsui_grid-variant-text-grid_vj6p7_gvt2c_112 > * {
  padding: 0 var(--space-grid-gutter-0subky, 20px);
  margin: var(--space-text-grid-vertical-tj8m1c, 16px) 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153 > *:not(#\9) {
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-breakpoint-default_vj6p7_gvt2c_119 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-1_vj6p7_gvt2c_122.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-1_vj6p7_gvt2c_122.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-2_vj6p7_gvt2c_128.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-2_vj6p7_gvt2c_128.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(3n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-vertical-borders_vj6p7_gvt2c_153.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-child(4n) {
  border-right-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186:not(#\9) {
  /* stylelint-disable selector-max-universal */
  /* stylelint-enable selector-max-universal */
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186 > *:not(#\9) {
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186 > *:not(#\9):last-child {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-2_vj6p7_gvt2c_128.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-2_vj6p7_gvt2c_128.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(2):nth-child(3n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(2):nth-child(3n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-3_vj6p7_gvt2c_134.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(3):nth-child(3n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(2):nth-child(4n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(2):nth-child(4n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(2):nth-child(4n+3) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(3):nth-child(4n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(3):nth-child(4n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vj6p7_gvt2c_93 > .awsui_grid_vj6p7_gvt2c_104.awsui_grid-horizontal-borders_vj6p7_gvt2c_186.awsui_grid-columns-4_vj6p7_gvt2c_140.awsui_grid-breakpoint-xs_vj6p7_gvt2c_125 > *:not(#\9):nth-last-child(4):nth-child(4n+1) {
  border-bottom-width: 0;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_vj6p7_gvt2c_240:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  display: block;
}

.awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
  box-sizing: border-box;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-default-e47bkv, #7d8998);
  border-radius: var(--border-radius-tiles-kl8mtb, 8px);
  background: var(--color-background-input-default-u57378, #ffffff);
  padding: var(--space-xs-rsr2qu, 8px) var(--space-scaled-m-pv0fmt, 16px);
  display: flex;
  flex-direction: column;
  transition: border-color var(--motion-duration-transition-quick-x85tae, 90ms) var(--motion-easing-transition-quick-lukbd8, linear), background-color var(--motion-duration-transition-quick-x85tae, 90ms) var(--motion-easing-transition-quick-lukbd8, linear);
}
.awsui_tile-container_vj6p7_gvt2c_255.awsui_has-metadata_vj6p7_gvt2c_265:not(#\9) {
  padding-bottom: var(--space-s-hv8c1d, 12px);
}
.awsui_tile-container_vj6p7_gvt2c_255.awsui_selected_vj6p7_gvt2c_268:not(#\9) {
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-selected-ppkssz, #0972d3);
  background: var(--color-background-item-selected-ebt4bi, #f2f8fd);
}
.awsui_tile-container_vj6p7_gvt2c_255.awsui_disabled_vj6p7_gvt2c_272:not(#\9) {
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-tiles-disabled-dp4h7y, #e9ebed);
  background: var(--color-background-tiles-disabled-byt5lx, #e9ebed);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_tile-container_vj6p7_gvt2c_255:not(#\9), .awsui-mode-entering .awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_columns_vj6p7_gvt2c_287:not(#\9) {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-1_vj6p7_gvt2c_292 > .awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
  margin: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(100% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-1_vj6p7_gvt2c_292 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xs_vj6p7_gvt2c_299:not(#\9) {
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(100% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-1_vj6p7_gvt2c_292 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xxs_vj6p7_gvt2c_304:not(#\9) {
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(100% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-2_vj6p7_gvt2c_309 > .awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
  margin: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(100% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-2_vj6p7_gvt2c_309 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xs_vj6p7_gvt2c_299:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(50% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-2_vj6p7_gvt2c_309 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xxs_vj6p7_gvt2c_304:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(50% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-3_vj6p7_gvt2c_326 > .awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
  margin: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(100% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-3_vj6p7_gvt2c_326 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xs_vj6p7_gvt2c_299:not(#\9) {
  flex: 0 0;
  flex-basis: calc(33.3333333333% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(33.3333333333% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-3_vj6p7_gvt2c_326 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xxs_vj6p7_gvt2c_304:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(50% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-4_vj6p7_gvt2c_343 > .awsui_tile-container_vj6p7_gvt2c_255:not(#\9) {
  margin: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(100% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-4_vj6p7_gvt2c_343 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xs_vj6p7_gvt2c_299:not(#\9) {
  flex: 0 0;
  flex-basis: calc(25% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(25% - var(--space-grid-gutter-0subky, 20px));
}
.awsui_columns_vj6p7_gvt2c_287.awsui_column-4_vj6p7_gvt2c_343 > .awsui_tile-container_vj6p7_gvt2c_255.awsui_breakpoint-xxs_vj6p7_gvt2c_304:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-0subky, 20px));
  max-width: calc(50% - var(--space-grid-gutter-0subky, 20px));
}

.awsui_control_vj6p7_gvt2c_361:not(#\9) {
  flex-grow: 1;
  margin-bottom: var(--space-s-hv8c1d, 12px);
}
.awsui_control_vj6p7_gvt2c_361.awsui_no-image_vj6p7_gvt2c_365:not(#\9) {
  margin-bottom: 0;
}

.awsui_image_vj6p7_gvt2c_369:not(#\9) {
  text-align: center;
  flex: none;
}
.awsui_image_vj6p7_gvt2c_369 > img:not(#\9) {
  max-width: 100%;
}
.awsui_image_vj6p7_gvt2c_369.awsui_disabled_vj6p7_gvt2c_272 > img:not(#\9) {
  opacity: 0.3;
}