/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_2rhyz_crhmh_93:not(#\9) {
  /* used in test-utils for component to distinguish input from other input-like components, for example autosuggest */
}

.awsui_input_2rhyz_crhmh_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-field-horizontal-89h2yr, 12px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  width: 100%;
  cursor: text;
  box-sizing: border-box;
  background-color: var(--color-background-input-default-u57378, #ffffff);
  border-radius: var(--border-radius-input-txq8zy, 8px);
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-default-e47bkv, #7d8998);
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  height: var(--size-vertical-input-js5hfh, 34px);
}
.awsui_input_2rhyz_crhmh_97.awsui_input-readonly_2rhyz_crhmh_118:not(#\9) {
  background-color: var(--color-background-input-default-u57378, #ffffff);
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
}
.awsui_input_2rhyz_crhmh_97:not(#\9)::-webkit-input-placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  -webkit-user-select: none;
          user-select: none;
}
.awsui_input_2rhyz_crhmh_97:not(#\9)::-ms-input-placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}
.awsui_input_2rhyz_crhmh_97:not(#\9)::-moz-placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  opacity: 1;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):-ms-input-placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):-moz-placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  opacity: 1;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):focus {
  outline: 2px dotted transparent;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-input-txq8zy, 8px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_input_2rhyz_crhmh_97:not(#\9):disabled {
  background-color: var(--color-background-input-disabled-wu9j6u, #e9ebed);
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
  color: var(--color-text-input-disabled-oawyzo, #9ba7b6);
  cursor: auto;
  cursor: default;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):disabled::-webkit-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  -webkit-user-select: none;
          user-select: none;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):disabled::-ms-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
}
.awsui_input_2rhyz_crhmh_97:not(#\9):disabled::-moz-placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  opacity: 1;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):disabled:-ms-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
}
.awsui_input_2rhyz_crhmh_97:not(#\9):disabled:-moz-placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  opacity: 1;
}
.awsui_input_2rhyz_crhmh_97:not(#\9):invalid {
  box-shadow: none;
}
.awsui_input_2rhyz_crhmh_97.awsui_input-invalid_2rhyz_crhmh_179:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
  border-color: var(--color-text-status-error-5sesl6, #d91515);
  padding-left: calc(
  var(--space-field-horizontal-89h2yr, 12px) - (var(--border-invalid-width-5jpdqn, 8px) - var(--border-field-width-idlekx, 2px))
);
  border-left-width: var(--border-invalid-width-5jpdqn, 8px);
}
.awsui_input_2rhyz_crhmh_97.awsui_input-invalid_2rhyz_crhmh_179:not(#\9):focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-omt6il, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_input_2rhyz_crhmh_97.awsui_input-invalid_2rhyz_crhmh_179.awsui_input-has-icon-left_2rhyz_crhmh_190:not(#\9) {
  padding-left: calc(
        var(--space-field-icon-offset-25xih0, 36px) - (var(--border-invalid-width-5jpdqn, 8px) - var(--border-field-width-idlekx, 2px))
      );
}
.awsui_input_2rhyz_crhmh_97.awsui_input-type-search_2rhyz_crhmh_195:not(#\9) {
  box-sizing: border-box;
  -webkit-appearance: none; /* stylelint-disable-line plugin/no-unsupported-browser-features */
}
.awsui_input_2rhyz_crhmh_97.awsui_input-type-search_2rhyz_crhmh_195:not(#\9)::-ms-clear {
  display: none;
}
.awsui_input_2rhyz_crhmh_97.awsui_input-type-search_2rhyz_crhmh_195:not(#\9)::-webkit-search-decoration {
  -webkit-appearance: none; /* stylelint-disable-line plugin/no-unsupported-browser-features */
}
.awsui_input_2rhyz_crhmh_97.awsui_input-type-search_2rhyz_crhmh_195:not(#\9)::-webkit-search-cancel-button {
  display: none;
}
.awsui_input_2rhyz_crhmh_97.awsui_input-has-icon-left_2rhyz_crhmh_190:not(#\9) {
  padding-left: var(--space-field-icon-offset-25xih0, 36px);
}
.awsui_input_2rhyz_crhmh_97.awsui_input-has-icon-right_2rhyz_crhmh_211:not(#\9) {
  padding-right: var(--space-field-icon-offset-25xih0, 36px);
}
.awsui_input_2rhyz_crhmh_97.awsui_input-has-no-border-radius_2rhyz_crhmh_214:not(#\9) {
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
}

.awsui_input-container_2rhyz_crhmh_218:not(#\9) {
  display: flex;
  position: relative;
}

.awsui_input-icon_2rhyz_crhmh_223:not(#\9) {
  position: absolute;
}
.awsui_input-icon_2rhyz_crhmh_223:not(#\9):not(.awsui_input-icon-hoverable_2rhyz_crhmh_226) {
  pointer-events: none;
}

.awsui_input-icon-left_2rhyz_crhmh_230:not(#\9) {
  left: var(--space-field-horizontal-89h2yr, 12px);
  top: calc(50% - var(--font-body-m-line-height-i7xxvv, 22px) / 2);
}

.awsui_input-icon-right_2rhyz_crhmh_235:not(#\9) {
  right: var(--space-field-horizontal-89h2yr, 12px);
  top: calc(50% - var(--font-body-m-line-height-i7xxvv, 22px) / 2);
  color: var(--color-text-button-inline-icon-default-w65lqn, #0972d3);
}

.awsui_input-icon-hoverable_2rhyz_crhmh_226:not(#\9) {
  cursor: pointer;
}
.awsui_input-icon-hoverable_2rhyz_crhmh_226:not(#\9):hover {
  color: var(--color-text-button-inline-icon-hover-4a6rhe, #F07C00);
}