/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_18582_66aol_93:not(#\9) {
  display: flex;
}

.awsui_child_18582_66aol_97:not(#\9) {
  /* used in test-utils */
}

.awsui_child_18582_66aol_97:not(#\9):empty {
  display: none;
}

/*
 * Horizontal variant
 */
.awsui_horizontal_18582_66aol_108:not(#\9) {
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_horizontal-xxxs_18582_66aol_112:not(#\9) {
  margin-left: calc(-1 * var(--space-xxxs-k2w98v, 2px));
  margin-bottom: calc(-1 * var(--space-xxxs-k2w98v, 2px));
}
.awsui_horizontal-xxs_18582_66aol_116:not(#\9) {
  margin-left: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-bottom: calc(-1 * var(--space-xxs-ynfts5, 4px));
}
.awsui_horizontal-xs_18582_66aol_120:not(#\9) {
  margin-left: calc(-1 * var(--space-xs-rsr2qu, 8px));
  margin-bottom: calc(-1 * var(--space-xs-rsr2qu, 8px));
}
.awsui_horizontal-s_18582_66aol_124:not(#\9) {
  margin-left: calc(-1 * var(--space-s-hv8c1d, 12px));
  margin-bottom: calc(-1 * var(--space-s-hv8c1d, 12px));
}
.awsui_horizontal-m_18582_66aol_128:not(#\9) {
  margin-left: calc(-1 * var(--space-m-17eucw, 16px));
  margin-bottom: calc(-1 * var(--space-m-17eucw, 16px));
}
.awsui_horizontal-l_18582_66aol_132:not(#\9) {
  margin-left: calc(-1 * var(--space-l-4vl6xu, 20px));
  margin-bottom: calc(-1 * var(--space-l-4vl6xu, 20px));
}
.awsui_horizontal-xl_18582_66aol_136:not(#\9) {
  margin-left: calc(-1 * var(--space-xl-a39hup, 24px));
  margin-bottom: calc(-1 * var(--space-xl-a39hup, 24px));
}
.awsui_horizontal-xxl_18582_66aol_140:not(#\9) {
  margin-left: calc(-1 * var(--space-xxl-2nvmf1, 32px));
  margin-bottom: calc(-1 * var(--space-xxl-2nvmf1, 32px));
}

.awsui_child-horizontal-xxxs_18582_66aol_145:not(#\9) {
  margin-left: var(--space-xxxs-k2w98v, 2px);
  margin-bottom: var(--space-xxxs-k2w98v, 2px);
  min-width: 1px;
}

.awsui_child-horizontal-xxs_18582_66aol_151:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
  margin-bottom: var(--space-xxs-ynfts5, 4px);
  min-width: 1px;
}

.awsui_child-horizontal-xs_18582_66aol_157:not(#\9) {
  margin-left: var(--space-xs-rsr2qu, 8px);
  margin-bottom: var(--space-xs-rsr2qu, 8px);
  min-width: 1px;
}

.awsui_child-horizontal-s_18582_66aol_163:not(#\9) {
  margin-left: var(--space-s-hv8c1d, 12px);
  margin-bottom: var(--space-s-hv8c1d, 12px);
  min-width: 1px;
}

.awsui_child-horizontal-m_18582_66aol_169:not(#\9) {
  margin-left: var(--space-m-17eucw, 16px);
  margin-bottom: var(--space-m-17eucw, 16px);
  min-width: 1px;
}

.awsui_child-horizontal-l_18582_66aol_175:not(#\9) {
  margin-left: var(--space-l-4vl6xu, 20px);
  margin-bottom: var(--space-l-4vl6xu, 20px);
  min-width: 1px;
}

.awsui_child-horizontal-xl_18582_66aol_181:not(#\9) {
  margin-left: var(--space-xl-a39hup, 24px);
  margin-bottom: var(--space-xl-a39hup, 24px);
  min-width: 1px;
}

.awsui_child-horizontal-xxl_18582_66aol_187:not(#\9) {
  margin-left: var(--space-xxl-2nvmf1, 32px);
  margin-bottom: var(--space-xxl-2nvmf1, 32px);
  min-width: 1px;
}

/*
 * Vertical variant
 */
.awsui_vertical_18582_66aol_196:not(#\9) {
  flex-direction: column;
}

.awsui_child-vertical-xxxs_18582_66aol_200:not(#\9):not(:last-child) {
  margin-bottom: var(--space-xxxs-k2w98v, 2px);
}

.awsui_child-vertical-xxs_18582_66aol_204:not(#\9):not(:last-child) {
  margin-bottom: var(--space-xxs-ynfts5, 4px);
}

.awsui_child-vertical-xs_18582_66aol_208:not(#\9):not(:last-child) {
  margin-bottom: var(--space-xs-rsr2qu, 8px);
}

.awsui_child-vertical-s_18582_66aol_212:not(#\9):not(:last-child) {
  margin-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_child-vertical-m_18582_66aol_216:not(#\9):not(:last-child) {
  margin-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_child-vertical-l_18582_66aol_220:not(#\9):not(:last-child) {
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_child-vertical-xl_18582_66aol_224:not(#\9):not(:last-child) {
  margin-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_child-vertical-xxl_18582_66aol_228:not(#\9):not(:last-child) {
  margin-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}