/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_link_4c84z_1c30c_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  display: inline;
  white-space: inherit;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93:not(#\9):active, .awsui_link_4c84z_1c30c_93:not(#\9):focus, .awsui_link_4c84z_1c30c_93:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_variant-secondary_4c84z_1c30c_138:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: var(--font-link-primary-weight-91pybi, "inherit");
  letter-spacing: var(--font-link-primary-letter-spacing-vmsgcx, "inherit");
  -webkit-text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
          text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: var(--color-text-link-primary-underline-ga7rq8, #0972d3);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_variant-primary_4c84z_1c30c_173:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: var(--font-button-weight-7rj5fx, 800);
  letter-spacing: 0.005em;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_variant-info_4c84z_1c30c_208:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: var(--color-text-link-button-underline-hover-ei09le, transparent);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: var(--font-box-value-large-weight-dyt1pj, 800);
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_variant-value-large_4c84z_1c30c_243:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9) {
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  font-weight: 700;
  letter-spacing: 0.005em;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-interactive-hover-3c98wn, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9):active {
  color: var(--color-text-interactive-active-vol84d, #000716);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_variant-top-navigation_4c84z_1c30c_277:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: var(--font-link-button-weight-4f1hjr, 800);
  letter-spacing: var(--font-link-button-letter-spacing-58wset, 0.005em);
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: var(--color-text-link-button-underline-8mp92t, transparent);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_variant-recovery_4c84z_1c30c_312:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: var(--color-text-link-button-underline-hover-ei09le, transparent);
}
.awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9) {
  color: var(--color-text-link-button-normal-default-2yrp9u, #0972d3);
  font-weight: var(--font-button-weight-7rj5fx, 800);
  letter-spacing: var(--font-button-letter-spacing-8y5poz, 0.005em);
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9), .awsui-mode-entering .awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-button-normal-hover-gz8y5b, #033160);
}
.awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9):active {
  color: var(--color-text-link-button-normal-active-8lhs8f, #033160);
}
.awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9):active, .awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9):focus, .awsui_link_4c84z_1c30c_93.awsui_button_4c84z_1c30c_347:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
}
.awsui_link_4c84z_1c30c_93.awsui_color-inverted_4c84z_1c30c_382:not(#\9) {
  color: var(--color-text-notification-default-2kpcxy, #fbfbfb);
}
.awsui_link_4c84z_1c30c_93.awsui_color-inverted_4c84z_1c30c_382:not(#\9):not(.awsui_button_4c84z_1c30c_347) {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_1c30c_93.awsui_color-inverted_4c84z_1c30c_382:not(#\9):hover {
  color: var(--color-text-link-inverted-hover-5tnsdi, #ffffff);
}
.awsui_link_4c84z_1c30c_93[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-k2wccv, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-nv4ahb, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-body-s_4c84z_1c30c_401:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-body-m_4c84z_1c30c_406:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-heading-xs_4c84z_1c30c_410:not(#\9) {
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-heading-s_4c84z_1c30c_414:not(#\9) {
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-heading-m_4c84z_1c30c_419:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-heading-l_4c84z_1c30c_424:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-heading-xl_4c84z_1c30c_429:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-display-l_4c84z_1c30c_434:not(#\9) {
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
}
.awsui_link_4c84z_1c30c_93.awsui_font-size-inherit_4c84z_1c30c_439:not(#\9) {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.awsui_icon-wrapper_4c84z_1c30c_445:not(#\9) {
  white-space: nowrap;
}

.awsui_icon_4c84z_1c30c_445:not(#\9) {
  display: inline-block;
}