/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_content_1wepg_3hhd4_93:not(#\9),
.awsui_description_1wepg_3hhd4_94:not(#\9),
.awsui_label_1wepg_3hhd4_95:not(#\9) {
  display: block;
}

.awsui_outline_1wepg_3hhd4_99:not(#\9) {
  display: none;
}

.awsui_native-input_1wepg_3hhd4_103[data-awsui-focus-visible=true]:not(#\9):focus {
  /* stylelint-disable-next-line selector-max-type, @cloudscape-design/no-implicit-descendant */
}
.awsui_native-input_1wepg_3hhd4_103[data-awsui-focus-visible=true]:not(#\9):focus + .awsui_outline_1wepg_3hhd4_99 {
  display: block;
}

.awsui_wrapper_1wepg_3hhd4_110:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  display: flex;
}

.awsui_label-wrapper_1wepg_3hhd4_117:not(#\9) {
  position: relative;
  display: flex;
  cursor: default;
}

.awsui_content_1wepg_3hhd4_93:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_empty-content_1wepg_3hhd4_129:not(#\9) {
  width: 0px;
}

.awsui_description_1wepg_3hhd4_94:not(#\9) {
  color: var(--color-text-form-secondary-ih9x7l, #5f6b7a);
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_description-bottom-padding_1wepg_3hhd4_139:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_label_1wepg_3hhd4_95:not(#\9),
.awsui_description_1wepg_3hhd4_94:not(#\9) {
  padding-left: var(--space-xs-rsr2qu, 8px);
}
.awsui_label-disabled_1wepg_3hhd4_147:not(#\9),
.awsui_description-disabled_1wepg_3hhd4_148:not(#\9) {
  color: var(--color-text-control-disabled-3z3eul, #9ba7b6);
}

/* stylelint-disable selector-max-type */
.awsui_control_1wepg_3hhd4_153:not(#\9) {
  position: relative;
}
.awsui_control_1wepg_3hhd4_153 > input:not(#\9), .awsui_control_1wepg_3hhd4_153 > svg:not(#\9), .awsui_control_1wepg_3hhd4_153 > .awsui_outline_1wepg_3hhd4_99:not(#\9) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.awsui_control_1wepg_3hhd4_153 > input:not(#\9) {
  opacity: 0;
  z-index: 1;
  -webkit-user-select: none;
          user-select: none;
  cursor: default;
  margin: 0;
}

/* stylelint-enable selector-max-type */