/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_content_vjswe_19wtj_97:not(#\9) {
  /* used in test-utils */
}

.awsui_button_vjswe_19wtj_101:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  border-radius: var(--border-radius-button-gps9uv, 4px);
  border: var(--border-field-width-idlekx, 2px) solid;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-l-4vl6xu, 20px);
  font-weight: var(--font-button-weight-7rj5fx, 800);
  letter-spacing: var(--font-button-letter-spacing-8y5poz, 0.005em);
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122:not(#\9) {
  background: var(--color-background-button-normal-default-syw2p1, #ffffff);
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: var(--color-border-button-normal-default-djv8f5, #0972d3);
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122:not(#\9):hover {
  background: var(--color-background-button-normal-hover-c3qbgm, #f2f8fd);
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: var(--color-border-button-normal-hover-e1d0kh, #033160);
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: var(--color-background-button-normal-active-9lyks0, #d3e7f9);
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: var(--color-border-button-normal-active-zbmaft, #033160);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-normal_vjswe_19wtj_122.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: var(--color-background-button-normal-disabled-z5vd4i, #ffffff);
  border-color: var(--color-border-button-normal-disabled-262a0x, #9ba7b6);
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206:not(#\9) {
  background: var(--color-background-button-primary-default-m2j9xt, #F07C00);
  color: var(--color-text-button-primary-default-340wh6, #ffffff);
  border-color: var(--color-background-button-primary-default-m2j9xt, #F07C00);
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206:not(#\9):hover {
  background: var(--color-background-button-primary-hover-yil0he, #f89327);
  color: var(--color-text-button-primary-hover-vzqjpi, #ffffff);
  border-color: var(--color-background-button-primary-hover-yil0he, #f89327);
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: var(--color-background-button-primary-active-16781v, #F07C00);
  color: var(--color-text-button-primary-active-vems2x, #ffffff);
  border-color: var(--color-background-button-primary-active-16781v, #F07C00);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-primary_vjswe_19wtj_206.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: var(--color-background-button-primary-disabled-emdb75, #ac7031);
  border-color: var(--color-border-button-primary-disabled-xxgc01, #ac7031);
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290:not(#\9) {
  background: transparent;
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290:not(#\9):hover {
  background: var(--color-background-button-link-hover-69sbpz, #f2f8fd);
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: var(--color-background-button-link-hover-69sbpz, #f2f8fd);
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: var(--color-background-button-link-active-t13dka, #d3e7f9);
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: var(--color-background-button-link-active-t13dka, #d3e7f9);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-link_vjswe_19wtj_290.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162:not(#\9):hover {
  background: transparent;
  color: var(--color-text-interactive-hover-3c98wn, #F07C00);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180:not(#\9) {
  background: transparent;
  color: var(--color-text-button-inline-icon-default-w65lqn, #0972d3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180:not(#\9):hover {
  background: transparent;
  color: var(--color-text-button-inline-icon-hover-4a6rhe, #F07C00);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: transparent;
  color: var(--color-text-button-inline-icon-default-w65lqn, #0972d3);
  border-color: transparent;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-button-inline-icon-disabled-gfhvz7, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162:not(#\9):hover {
  background: transparent;
  color: var(--color-text-interactive-hover-3c98wn, #F07C00);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-inverted-default-phki5u, #d1d5db);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162:not(#\9):hover {
  background: transparent;
  color: var(--color-text-interactive-inverted-hover-g2obc6, #fbfbfb);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-inverted-default-phki5u, #d1d5db);
  border-color: transparent;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710:not(#\9) {
  background: transparent;
  color: var(--color-text-link-default-5f186r, #0972d3);
  border-color: transparent;
  border-width: 0;
  position: relative;
  text-decoration: none;
  padding: 0;
  font-weight: normal;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710:not(#\9):hover {
  background: transparent;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: transparent;
  color: var(--color-text-link-default-5f186r, #0972d3);
  border-color: transparent;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-breadcrumb-group_vjswe_19wtj_710.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797:not(#\9) {
  background: transparent;
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797:not(#\9):hover {
  background: transparent;
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797:not(#\9):active, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_is-activated_vjswe_19wtj_135:not(#\9) {
  background: transparent;
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: transparent;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-modal-dismiss_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-flashbar-icon_vjswe_19wtj_162[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_variant-inline-icon_vjswe_19wtj_180[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-menu-trigger_vjswe_19wtj_797.awsui_disabled_vjswe_19wtj_198:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_19wtj_101.awsui_button-no-text_vjswe_19wtj_881:not(#\9) {
  padding-left: var(--space-button-icon-only-horizontal-nidslr, 7px);
  padding-right: var(--space-button-icon-only-horizontal-nidslr, 7px);
}
.awsui_button_vjswe_19wtj_101.awsui_button-no-wrap_vjswe_19wtj_885:not(#\9) {
  white-space: nowrap;
}
.awsui_button_vjswe_19wtj_101.awsui_variant-icon_vjswe_19wtj_162:not(#\9), .awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180:not(#\9), .awsui_button_vjswe_19wtj_101.awsui_variant-flashbar-icon_vjswe_19wtj_162:not(#\9) {
  padding-left: var(--space-xxs-ynfts5, 4px);
  padding-right: var(--space-xxs-ynfts5, 4px);
}
.awsui_button_vjswe_19wtj_101.awsui_variant-modal-dismiss_vjswe_19wtj_162:not(#\9) {
  padding: var(--space-button-modal-dismiss-vertical-r4lx2k, 0px) var(--space-xxs-ynfts5, 4px);
  margin-right: calc(-1 * var(--space-xxs-ynfts5, 4px));
}
.awsui_button_vjswe_19wtj_101.awsui_variant-inline-icon_vjswe_19wtj_180:not(#\9) {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}
.awsui_button_vjswe_19wtj_101 > .awsui_icon-left_vjswe_19wtj_901:not(#\9) {
  position: relative;
  left: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-right: var(--space-xxs-ynfts5, 4px);
}
.awsui_button_vjswe_19wtj_101 > .awsui_icon-right_vjswe_19wtj_906:not(#\9) {
  position: relative;
  right: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-left: var(--space-xxs-ynfts5, 4px);
}
.awsui_button_vjswe_19wtj_101.awsui_button-no-text_vjswe_19wtj_881 > .awsui_icon_vjswe_19wtj_901:not(#\9) {
  margin-right: auto;
  margin-left: auto;
  right: 0;
  left: 0;
}