/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_link_1kosq_7k1hd_93:not(#\9):after {
  display: none;
}

.awsui_breadcrumb_1kosq_7k1hd_97:not(#\9) {
  display: flex;
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_icon_1kosq_7k1hd_100:not(#\9) {
  margin: 0 var(--space-xs-rsr2qu, 8px);
  color: var(--color-text-breadcrumb-icon-mlugp6, #7d8998);
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9), .awsui-mode-entering .awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9):focus {
  outline: none;
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9):active {
  color: var(--color-text-link-hover-lutsf6, #F07C00);
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9):active, .awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9):focus, .awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}
.awsui_breadcrumb_1kosq_7k1hd_97 > .awsui_anchor_1kosq_7k1hd_104[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-k2wccv, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-nv4ahb, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_breadcrumb_1kosq_7k1hd_97.awsui_last_1kosq_7k1hd_147 > .awsui_icon_1kosq_7k1hd_100:not(#\9) {
  display: none;
}
.awsui_breadcrumb_1kosq_7k1hd_97.awsui_last_1kosq_7k1hd_147 > .awsui_anchor_1kosq_7k1hd_104:not(#\9) {
  color: var(--color-text-breadcrumb-current-mum16s, #5f6b7a);
  font-weight: 700;
  text-decoration: none;
  cursor: default;
}

.awsui_compressed_1kosq_7k1hd_157:not(#\9) {
  min-width: 0;
  overflow: hidden;
}
.awsui_compressed_1kosq_7k1hd_157 > .awsui_text_1kosq_7k1hd_161:not(#\9) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.awsui_virtual-item_1kosq_7k1hd_168:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  visibility: hidden;
}

.awsui_item-popover_1kosq_7k1hd_175:not(#\9) {
  /* used in tests */
}