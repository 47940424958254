/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  animation: awsui_awsui-motion-fade-in-dropdown_qwoo0_1aum0_1 var(--motion-duration-show-quick-tyaalm, 135ms) var(--motion-easing-show-quick-1fcgbv, ease-out);
}
@keyframes awsui_awsui-motion-fade-in-dropdown_qwoo0_1aum0_1 {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9), .awsui-mode-entering .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_dropdown-content-wrapper_qwoo0_1aum0_93.awsui_refresh_qwoo0_1aum0_114:not(#\9) {
  transform-origin: top;
  animation-name: awsui_awsui-motion-fade-in-0_qwoo0_1aum0_1;
  animation-duration: var(--motion-duration-show-quick-tyaalm, 135ms);
  animation-timing-function: var(--motion-easing-show-quick-1fcgbv, ease-out);
}
@keyframes awsui_awsui-motion-fade-in-0_qwoo0_1aum0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown-content-wrapper_qwoo0_1aum0_93.awsui_refresh_qwoo0_1aum0_114:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown-content-wrapper_qwoo0_1aum0_93.awsui_refresh_qwoo0_1aum0_114:not(#\9), .awsui-mode-entering .awsui_dropdown-content-wrapper_qwoo0_1aum0_93.awsui_refresh_qwoo0_1aum0_114:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_qwoo0_1aum0_143:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  position: relative;
  white-space: inherit;
}
.awsui_root_qwoo0_1aum0_143.awsui_interior_qwoo0_1aum0_155:not(#\9) {
  position: static;
}

.awsui_dropdown_qwoo0_1aum0_93:not(#\9) {
  position: absolute;
  display: none;
  z-index: 2000;
  -webkit-user-select: none;
          user-select: none;
  transform-origin: top;
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_use-portal_qwoo0_1aum0_166:not(#\9) {
  z-index: 7000;
}
.awsui_dropdown_qwoo0_1aum0_93:not(#\9):not(.awsui_interior_qwoo0_1aum0_155) {
  transform: translateY(0px);
  transition: transform var(--motion-duration-show-quick-tyaalm, 135ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown_qwoo0_1aum0_93:not(#\9):not(.awsui_interior_qwoo0_1aum0_155) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown_qwoo0_1aum0_93:not(#\9):not(.awsui_interior_qwoo0_1aum0_155), .awsui-mode-entering .awsui_dropdown_qwoo0_1aum0_93:not(#\9):not(.awsui_interior_qwoo0_1aum0_155) {
  animation: none;
  transition: none;
}
.awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  position: relative;
  background-color: var(--color-background-dropdown-item-default-fzdqsg, #ffffff);
  outline: none;
  box-shadow: var(--shadow-dropdown-99h0gz, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-container-top-srcvx4, transparent);
  box-sizing: border-box;
  border-bottom: none;
}
.awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9)::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: transparent;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-dropdown-container-tyn0js, #9ba7b6);
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_dropdown-drop-up_qwoo0_1aum0_211 > .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  transform-origin: bottom;
  box-shadow: var(--shadow-dropup-11esug, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
}
.awsui_dropdown-drop-up_qwoo0_1aum0_211.awsui_with-limited-width_qwoo0_1aum0_215 > .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  border-top: none;
}
.awsui_dropdown-drop-left_qwoo0_1aum0_218:not(#\9) {
  right: 0;
}
.awsui_dropdown-drop-right_qwoo0_1aum0_221:not(#\9) {
  left: 0;
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_occupy-entire-width_qwoo0_1aum0_224:not(#\9) {
  min-width: 100%;
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_hide-upper-border_qwoo0_1aum0_227 > .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  border-top: none;
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_interior_qwoo0_1aum0_155 > .awsui_dropdown-content-wrapper_qwoo0_1aum0_93:not(#\9) {
  margin-top: -1px;
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-container-top-srcvx4, transparent);
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_open_qwoo0_1aum0_234:not(#\9) {
  display: block;
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_open_qwoo0_1aum0_234:not(#\9):not(.awsui_interior_qwoo0_1aum0_155).awsui_refresh_qwoo0_1aum0_114[data-animating=true] {
  transform: translateY(4px);
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_open_qwoo0_1aum0_234:not(#\9):not(.awsui_interior_qwoo0_1aum0_155).awsui_refresh_qwoo0_1aum0_114[data-animating=true].awsui_dropdown-drop-up_qwoo0_1aum0_211 {
  transform: translateY(-4px);
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_open_qwoo0_1aum0_234.awsui_nowrap_qwoo0_1aum0_243:not(#\9) {
  white-space: nowrap;
  overflow: scroll;
}
.awsui_dropdown_qwoo0_1aum0_93.awsui_is-empty_qwoo0_1aum0_247:not(#\9)::after {
  display: none;
}

.awsui_dropdown-content_qwoo0_1aum0_93:not(#\9) {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.awsui_ie11-wrapper_qwoo0_1aum0_257:not(#\9) {
  display: flex;
}

.awsui_stretch-trigger-height_qwoo0_1aum0_261:not(#\9) {
  height: 100%;
}