/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui_modal-slide-up_1d2i7_1q6b4_1 {
  0% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.awsui_dialog_1d2i7_1q6b4_105:not(#\9) {
  animation: awsui_modal-slide-up_1d2i7_1q6b4_1 var(--motion-duration-slow-0273ow, 180ms) ease-out, awsui_awsui-motion-fade-in-0_1d2i7_1q6b4_1 var(--motion-duration-slow-0273ow, 180ms) ease-out;
  animation-delay: var(--motion-duration-fast-nk2ap0, 90ms);
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in-0_1d2i7_1q6b4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dialog_1d2i7_1q6b4_105:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dialog_1d2i7_1q6b4_105:not(#\9), .awsui-mode-entering .awsui_dialog_1d2i7_1q6b4_105:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_dialog_1d2i7_1q6b4_105.awsui_refresh_1d2i7_1q6b4_128:not(#\9) {
  animation: awsui_awsui-motion-scale-popup_1d2i7_1q6b4_1, awsui_awsui-motion-fade-in-0_1d2i7_1q6b4_1;
  animation-duration: var(--motion-duration-refresh-only-fast-addxjd, 115ms);
  animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in-0_1d2i7_1q6b4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-scale-popup_1d2i7_1q6b4_1 {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dialog_1d2i7_1q6b4_105.awsui_refresh_1d2i7_1q6b4_128:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dialog_1d2i7_1q6b4_105.awsui_refresh_1d2i7_1q6b4_128:not(#\9), .awsui-mode-entering .awsui_dialog_1d2i7_1q6b4_105.awsui_refresh_1d2i7_1q6b4_128:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_1d2i7_1q6b4_161:not(#\9) {
  animation: awsui_awsui-motion-fade-in_1d2i7_1q6b4_1 var(--motion-duration-extra-slow-8ks5rs, 270ms) ease-out;
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in_1d2i7_1q6b4_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_root_1d2i7_1q6b4_161:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_root_1d2i7_1q6b4_161:not(#\9), .awsui-mode-entering .awsui_root_1d2i7_1q6b4_161:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_root_1d2i7_1q6b4_161.awsui_refresh_1d2i7_1q6b4_128:not(#\9) {
  animation-duration: var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_root_1d2i7_1q6b4_161.awsui_refresh_1d2i7_1q6b4_128:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_root_1d2i7_1q6b4_161.awsui_refresh_1d2i7_1q6b4_128:not(#\9), .awsui-mode-entering .awsui_root_1d2i7_1q6b4_161.awsui_refresh_1d2i7_1q6b4_128:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1d2i7_1q6b4_161:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  background-color: var(--color-background-modal-overlay-17dapf, rgba(35, 47, 62, 0.7));
  display: flex;
  align-items: center;
  justify-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 5000;
  outline: 0;
  overflow: auto;
  cursor: pointer;
}
.awsui_root_1d2i7_1q6b4_161.awsui_hidden_1d2i7_1q6b4_224:not(#\9) {
  display: none;
}

.awsui_focus-lock_1d2i7_1q6b4_228:not(#\9) {
  align-self: flex-start;
  margin: auto;
  padding: var(--space-s-hv8c1d, 12px) 0;
  z-index: 5000;
  pointer-events: none;
}

.awsui_dialog_1d2i7_1q6b4_105:not(#\9) {
  position: static;
  top: 0;
  transform: translate(0, 0);
  width: calc(100vw - var(--space-s-hv8c1d, 12px) * 2);
  box-sizing: border-box;
  outline: none;
  z-index: 5000;
  pointer-events: all;
}
.awsui_dialog_1d2i7_1q6b4_105.awsui_small_1d2i7_1q6b4_246:not(#\9) {
  max-width: 320px;
}
.awsui_dialog_1d2i7_1q6b4_105.awsui_medium_1d2i7_1q6b4_249:not(#\9) {
  max-width: 600px;
}
.awsui_dialog_1d2i7_1q6b4_105.awsui_large_1d2i7_1q6b4_252:not(#\9) {
  max-width: 820px;
}
.awsui_dialog_1d2i7_1q6b4_105.awsui_max_1d2i7_1q6b4_255.awsui_breakpoint-xs_1d2i7_1q6b4_255:not(#\9) {
  max-width: calc(100vw - (2 * 4 * 10px + var(--space-xxxl-bo9z7f, 40px)));
  margin: auto;
}

.awsui_container_1d2i7_1q6b4_260:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  display: block;
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  box-shadow: var(--shadow-container-7nblnj, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
  box-shadow: var(--shadow-modal-3qhlqt, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  word-wrap: break-word;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_container_1d2i7_1q6b4_260:not(#\9) {
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}

.awsui_content_1d2i7_1q6b4_284:not(#\9) {
  padding: var(--space-container-content-top-fsd8nr, 0px) var(--space-modal-horizontal-738tw9, 24px) var(--space-modal-content-bottom-cecclv, 16px);
}
.awsui_content_1d2i7_1q6b4_284.awsui_no-paddings_1d2i7_1q6b4_287:not(#\9) {
  padding: 0;
}

.awsui_header_1d2i7_1q6b4_291:not(#\9) {
  padding: var(--space-s-hv8c1d, 12px) var(--space-modal-horizontal-738tw9, 24px);
  background-color: var(--color-background-container-header-4flbq5, #ffffff);
  border-bottom: 1px solid var(--color-border-container-divider-4ade7z, transparent);
  border-radius: var(--border-radius-container-gh9ysk, 16px) var(--border-radius-container-gh9ysk, 16px) 0 0;
}

.awsui_header--text_1d2i7_1q6b4_298:not(#\9) {
  /* used in test-utils */
}

.awsui_footer_1d2i7_1q6b4_302:not(#\9) {
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-container-horizontal-ddrezg, 24px);
}
.awsui_footer_1d2i7_1q6b4_302:not(#\9):after {
  content: "";
  display: table;
  clear: both;
}

.awsui_dismiss-control_1d2i7_1q6b4_312:not(#\9) {
  /* used in test-utils */
}

.awsui_modal-open_1d2i7_1q6b4_316:not(#\9) {
  overflow: hidden;
  /*
   * When padding-right is added to account for scrollbar being turned
   * off by overflow:hidden, that padding will go offscreen and have no
   * effect if box-sizing is content-box and body width is 100%.  Set
   * border-box to avoid this; this should be safe as box-sizing isn't
   * inherited by child elements normally.
   */
  box-sizing: border-box;
}