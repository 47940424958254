/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_18wu0_1xtkt_93:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
}

/* stylelint-disable no-invalid-position-at-import-rule */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
.awsui_box_18wu0_1xtkt_205.awsui_p-variant_18wu0_1xtkt_205.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_b-variant_18wu0_1xtkt_205.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_strong-variant_18wu0_1xtkt_205.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_code-variant_18wu0_1xtkt_205.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_pre-variant_18wu0_1xtkt_205.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_samp-variant_18wu0_1xtkt_205.awsui_color-default_18wu0_1xtkt_205:not(#\9) {
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_box_18wu0_1xtkt_205.awsui_h1-variant_18wu0_1xtkt_209.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h2-variant_18wu0_1xtkt_209.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h3-variant_18wu0_1xtkt_209.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h4-variant_18wu0_1xtkt_209.awsui_color-default_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h5-variant_18wu0_1xtkt_209.awsui_color-default_18wu0_1xtkt_205:not(#\9) {
  color: var(--color-text-heading-default-jopz0f, #000716);
}

.awsui_box_18wu0_1xtkt_205.awsui_small-variant_18wu0_1xtkt_213.awsui_color-default_18wu0_1xtkt_205:not(#\9) {
  color: var(--color-text-small-le1y42, #5f6b7a);
}

.awsui_box_18wu0_1xtkt_205.awsui_a-variant_18wu0_1xtkt_217.awsui_color-default_18wu0_1xtkt_205:not(#\9) {
  color: var(--color-text-link-default-5f186r, #0972d3);
}

.awsui_box_18wu0_1xtkt_205.awsui_small-variant_18wu0_1xtkt_213.awsui_font-size-default_18wu0_1xtkt_221:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_code-variant_18wu0_1xtkt_205.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-variant_18wu0_1xtkt_205.awsui_font-size-default_18wu0_1xtkt_221:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_b-variant_18wu0_1xtkt_205.awsui_font-size-default_18wu0_1xtkt_221:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_strong-variant_18wu0_1xtkt_205.awsui_font-size-default_18wu0_1xtkt_221:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_pre-variant_18wu0_1xtkt_205.awsui_font-size-default_18wu0_1xtkt_221:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_samp-variant_18wu0_1xtkt_205.awsui_font-size-default_18wu0_1xtkt_221:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_a-variant_18wu0_1xtkt_217.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}

.awsui_box_18wu0_1xtkt_205.awsui_h5-variant_18wu0_1xtkt_209.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}

.awsui_box_18wu0_1xtkt_205.awsui_h4-variant_18wu0_1xtkt_209.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}

.awsui_box_18wu0_1xtkt_205.awsui_h3-variant_18wu0_1xtkt_209.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}

.awsui_box_18wu0_1xtkt_205.awsui_h2-variant_18wu0_1xtkt_209.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}

.awsui_box_18wu0_1xtkt_205.awsui_h1-variant_18wu0_1xtkt_209.awsui_font-size-default_18wu0_1xtkt_221:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-variant_18wu0_1xtkt_205.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_code-variant_18wu0_1xtkt_205.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_pre-variant_18wu0_1xtkt_205.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_samp-variant_18wu0_1xtkt_205.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_small-variant_18wu0_1xtkt_213.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_a-variant_18wu0_1xtkt_217.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: 400;
}

.awsui_box_18wu0_1xtkt_205.awsui_strong-variant_18wu0_1xtkt_205.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_b-variant_18wu0_1xtkt_205.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: 700;
}

.awsui_box_18wu0_1xtkt_205.awsui_h5-variant_18wu0_1xtkt_209.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: var(--font-heading-xs-weight-wwznty, 800);
}

.awsui_box_18wu0_1xtkt_205.awsui_h4-variant_18wu0_1xtkt_209.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: var(--font-heading-s-weight-k8ys41, 800);
}

.awsui_box_18wu0_1xtkt_205.awsui_h3-variant_18wu0_1xtkt_209.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
}

.awsui_box_18wu0_1xtkt_205.awsui_h2-variant_18wu0_1xtkt_209.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
}

.awsui_box_18wu0_1xtkt_205.awsui_h1-variant_18wu0_1xtkt_209.awsui_font-weight-default_18wu0_1xtkt_261:not(#\9) {
  font-weight: var(--font-heading-xl-weight-772870, 800);
}

.awsui_box_18wu0_1xtkt_205.awsui_h1-variant_18wu0_1xtkt_209:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h2-variant_18wu0_1xtkt_209:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h3-variant_18wu0_1xtkt_209:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h4-variant_18wu0_1xtkt_209:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_h5-variant_18wu0_1xtkt_209:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_p-variant_18wu0_1xtkt_205:not(#\9) {
  margin: 0;
  text-decoration: none;
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
.awsui_box_18wu0_1xtkt_205.awsui_small-variant_18wu0_1xtkt_213:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_1xtkt_205.awsui_code-variant_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_pre-variant_18wu0_1xtkt_205:not(#\9), .awsui_box_18wu0_1xtkt_205.awsui_samp-variant_18wu0_1xtkt_205:not(#\9) {
  font-family: var(--font-family-monospace-iz223z, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background: transparent;
}
.awsui_box_18wu0_1xtkt_205.awsui_key-label-variant_18wu0_1xtkt_301:not(#\9) {
  font-weight: var(--font-display-label-weight-m18hjh, 700);
  color: var(--color-text-label-gxskdy, #000716);
  margin-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}
.awsui_box_18wu0_1xtkt_205.awsui_value-large-variant_18wu0_1xtkt_306:not(#\9) {
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
  font-weight: var(--font-box-value-large-weight-dyt1pj, 800);
  color: inherit;
}

.awsui_box_18wu0_1xtkt_205.awsui_color-inverted_18wu0_1xtkt_314:not(#\9) {
  color: var(--color-text-notification-default-2kpcxy, #fbfbfb);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-text-label_18wu0_1xtkt_317:not(#\9) {
  color: var(--color-text-label-gxskdy, #000716);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-text-body-secondary_18wu0_1xtkt_320:not(#\9) {
  color: var(--color-text-body-secondary-fdstdf, #414d5c);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-text-status-error_18wu0_1xtkt_323:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-text-status-success_18wu0_1xtkt_326:not(#\9) {
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-text-status-info_18wu0_1xtkt_329:not(#\9) {
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-text-status-inactive_18wu0_1xtkt_332:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_box_18wu0_1xtkt_205.awsui_color-inherit_18wu0_1xtkt_335:not(#\9) {
  color: inherit;
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-body-s_18wu0_1xtkt_338:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-body-m_18wu0_1xtkt_343:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-heading-xs_18wu0_1xtkt_347:not(#\9) {
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-heading-s_18wu0_1xtkt_351:not(#\9) {
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-heading-m_18wu0_1xtkt_356:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-heading-l_18wu0_1xtkt_361:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-heading-xl_18wu0_1xtkt_366:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-size-display-l_18wu0_1xtkt_371:not(#\9) {
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
}
.awsui_box_18wu0_1xtkt_205.awsui_font-weight-light_18wu0_1xtkt_376:not(#\9) {
  font-weight: 300;
}
.awsui_box_18wu0_1xtkt_205.awsui_font-weight-normal_18wu0_1xtkt_379:not(#\9) {
  font-weight: 400;
}
.awsui_box_18wu0_1xtkt_205.awsui_font-weight-bold_18wu0_1xtkt_382:not(#\9) {
  font-weight: 700;
}
.awsui_box_18wu0_1xtkt_205.awsui_font-weight-heavy_18wu0_1xtkt_385:not(#\9) {
  font-weight: var(--font-weight-heavy-ydka9r, 800);
}

.awsui_t-left_18wu0_1xtkt_389:not(#\9) {
  text-align: left;
}

.awsui_t-right_18wu0_1xtkt_393:not(#\9) {
  text-align: right;
}

.awsui_t-center_18wu0_1xtkt_397:not(#\9) {
  text-align: center;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_box_18wu0_1xtkt_205.awsui_p-n_18wu0_1xtkt_498:not(#\9) {
  padding: var(--space-scaled-none-cnv7sd, 0) var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-n_18wu0_1xtkt_502:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-n_18wu0_1xtkt_503:not(#\9) {
  padding-top: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-n_18wu0_1xtkt_507:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-n_18wu0_1xtkt_508:not(#\9) {
  padding-right: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-n_18wu0_1xtkt_512:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-n_18wu0_1xtkt_503:not(#\9) {
  padding-bottom: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-n_18wu0_1xtkt_517:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-n_18wu0_1xtkt_508:not(#\9) {
  padding-left: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-xxxs_18wu0_1xtkt_522:not(#\9) {
  padding: var(--space-scaled-xxxs-b6dm8t, 2px) var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-xxxs_18wu0_1xtkt_526:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxxs_18wu0_1xtkt_527:not(#\9) {
  padding-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-xxxs_18wu0_1xtkt_531:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxxs_18wu0_1xtkt_532:not(#\9) {
  padding-right: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-xxxs_18wu0_1xtkt_536:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxxs_18wu0_1xtkt_527:not(#\9) {
  padding-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-xxxs_18wu0_1xtkt_541:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxxs_18wu0_1xtkt_532:not(#\9) {
  padding-left: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-xxs_18wu0_1xtkt_546:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-xxs_18wu0_1xtkt_550:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxs_18wu0_1xtkt_551:not(#\9) {
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-xxs_18wu0_1xtkt_555:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxs_18wu0_1xtkt_556:not(#\9) {
  padding-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-xxs_18wu0_1xtkt_560:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxs_18wu0_1xtkt_551:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-xxs_18wu0_1xtkt_565:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxs_18wu0_1xtkt_556:not(#\9) {
  padding-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-xs_18wu0_1xtkt_570:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-xs_18wu0_1xtkt_574:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xs_18wu0_1xtkt_575:not(#\9) {
  padding-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-xs_18wu0_1xtkt_579:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xs_18wu0_1xtkt_580:not(#\9) {
  padding-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-xs_18wu0_1xtkt_584:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xs_18wu0_1xtkt_575:not(#\9) {
  padding-bottom: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-xs_18wu0_1xtkt_589:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xs_18wu0_1xtkt_580:not(#\9) {
  padding-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-s_18wu0_1xtkt_594:not(#\9) {
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-s_18wu0_1xtkt_598:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-s_18wu0_1xtkt_599:not(#\9) {
  padding-top: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-s_18wu0_1xtkt_603:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-s_18wu0_1xtkt_604:not(#\9) {
  padding-right: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-s_18wu0_1xtkt_608:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-s_18wu0_1xtkt_599:not(#\9) {
  padding-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-s_18wu0_1xtkt_613:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-s_18wu0_1xtkt_604:not(#\9) {
  padding-left: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-m_18wu0_1xtkt_618:not(#\9) {
  padding: var(--space-scaled-m-pv0fmt, 16px) var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-m_18wu0_1xtkt_622:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-m_18wu0_1xtkt_623:not(#\9) {
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-m_18wu0_1xtkt_627:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-m_18wu0_1xtkt_628:not(#\9) {
  padding-right: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-m_18wu0_1xtkt_632:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-m_18wu0_1xtkt_623:not(#\9) {
  padding-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-m_18wu0_1xtkt_637:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-m_18wu0_1xtkt_628:not(#\9) {
  padding-left: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-l_18wu0_1xtkt_517:not(#\9) {
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-l_18wu0_1xtkt_646:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-l_18wu0_1xtkt_647:not(#\9) {
  padding-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-l_18wu0_1xtkt_651:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-l_18wu0_1xtkt_652:not(#\9) {
  padding-right: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-l_18wu0_1xtkt_656:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-l_18wu0_1xtkt_647:not(#\9) {
  padding-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-l_18wu0_1xtkt_661:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-l_18wu0_1xtkt_652:not(#\9) {
  padding-left: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-xl_18wu0_1xtkt_666:not(#\9) {
  padding: var(--space-scaled-xl-w2t504, 24px) var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-xl_18wu0_1xtkt_670:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xl_18wu0_1xtkt_671:not(#\9) {
  padding-top: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-xl_18wu0_1xtkt_675:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xl_18wu0_1xtkt_676:not(#\9) {
  padding-right: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-xl_18wu0_1xtkt_680:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xl_18wu0_1xtkt_671:not(#\9) {
  padding-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-xl_18wu0_1xtkt_685:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xl_18wu0_1xtkt_676:not(#\9) {
  padding-left: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-xxl_18wu0_1xtkt_690:not(#\9) {
  padding: var(--space-scaled-xxl-pfgv96, 32px) var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-xxl_18wu0_1xtkt_694:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxl_18wu0_1xtkt_695:not(#\9) {
  padding-top: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-xxl_18wu0_1xtkt_699:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxl_18wu0_1xtkt_700:not(#\9) {
  padding-right: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-xxl_18wu0_1xtkt_704:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxl_18wu0_1xtkt_695:not(#\9) {
  padding-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-xxl_18wu0_1xtkt_709:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxl_18wu0_1xtkt_700:not(#\9) {
  padding-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-xxxl_18wu0_1xtkt_714:not(#\9) {
  padding: var(--space-scaled-xxxl-ffokcj, 40px) var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-top-xxxl_18wu0_1xtkt_718:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxxl_18wu0_1xtkt_719:not(#\9) {
  padding-top: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-right-xxxl_18wu0_1xtkt_723:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxxl_18wu0_1xtkt_724:not(#\9) {
  padding-right: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-bottom-xxxl_18wu0_1xtkt_728:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-vertical-xxxl_18wu0_1xtkt_719:not(#\9) {
  padding-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_p-left-xxxl_18wu0_1xtkt_733:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_p-horizontal-xxxl_18wu0_1xtkt_724:not(#\9) {
  padding-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-n_18wu0_1xtkt_738:not(#\9) {
  margin: var(--space-scaled-none-cnv7sd, 0) var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-n_18wu0_1xtkt_742:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-n_18wu0_1xtkt_743:not(#\9) {
  margin-top: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-n_18wu0_1xtkt_747:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-n_18wu0_1xtkt_748:not(#\9) {
  margin-right: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-n_18wu0_1xtkt_752:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-n_18wu0_1xtkt_743:not(#\9) {
  margin-bottom: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-n_18wu0_1xtkt_757:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-n_18wu0_1xtkt_748:not(#\9) {
  margin-left: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-xxxs_18wu0_1xtkt_762:not(#\9) {
  margin: var(--space-scaled-xxxs-b6dm8t, 2px) var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-xxxs_18wu0_1xtkt_766:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxxs_18wu0_1xtkt_767:not(#\9) {
  margin-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-xxxs_18wu0_1xtkt_771:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxxs_18wu0_1xtkt_772:not(#\9) {
  margin-right: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-xxxs_18wu0_1xtkt_776:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxxs_18wu0_1xtkt_767:not(#\9) {
  margin-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-xxxs_18wu0_1xtkt_781:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxxs_18wu0_1xtkt_772:not(#\9) {
  margin-left: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-xxs_18wu0_1xtkt_786:not(#\9) {
  margin: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-xxs_18wu0_1xtkt_790:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxs_18wu0_1xtkt_791:not(#\9) {
  margin-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-xxs_18wu0_1xtkt_795:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxs_18wu0_1xtkt_796:not(#\9) {
  margin-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-xxs_18wu0_1xtkt_800:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxs_18wu0_1xtkt_791:not(#\9) {
  margin-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-xxs_18wu0_1xtkt_805:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxs_18wu0_1xtkt_796:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-xs_18wu0_1xtkt_810:not(#\9) {
  margin: var(--space-scaled-xs-6859qs, 8px) var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-xs_18wu0_1xtkt_814:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xs_18wu0_1xtkt_815:not(#\9) {
  margin-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-xs_18wu0_1xtkt_819:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xs_18wu0_1xtkt_820:not(#\9) {
  margin-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-xs_18wu0_1xtkt_824:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xs_18wu0_1xtkt_815:not(#\9) {
  margin-bottom: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-xs_18wu0_1xtkt_829:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xs_18wu0_1xtkt_820:not(#\9) {
  margin-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-s_18wu0_1xtkt_834:not(#\9) {
  margin: var(--space-scaled-s-cu1hzn, 12px) var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-s_18wu0_1xtkt_838:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-s_18wu0_1xtkt_839:not(#\9) {
  margin-top: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-s_18wu0_1xtkt_843:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-s_18wu0_1xtkt_844:not(#\9) {
  margin-right: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-s_18wu0_1xtkt_848:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-s_18wu0_1xtkt_839:not(#\9) {
  margin-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-s_18wu0_1xtkt_853:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-s_18wu0_1xtkt_844:not(#\9) {
  margin-left: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-m_18wu0_1xtkt_858:not(#\9) {
  margin: var(--space-scaled-m-pv0fmt, 16px) var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-m_18wu0_1xtkt_862:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-m_18wu0_1xtkt_863:not(#\9) {
  margin-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-m_18wu0_1xtkt_867:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-m_18wu0_1xtkt_868:not(#\9) {
  margin-right: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-m_18wu0_1xtkt_872:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-m_18wu0_1xtkt_863:not(#\9) {
  margin-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-m_18wu0_1xtkt_877:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-m_18wu0_1xtkt_868:not(#\9) {
  margin-left: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-l_18wu0_1xtkt_757:not(#\9) {
  margin: var(--space-scaled-l-t03y3z, 20px) var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-l_18wu0_1xtkt_886:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-l_18wu0_1xtkt_887:not(#\9) {
  margin-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-l_18wu0_1xtkt_891:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-l_18wu0_1xtkt_892:not(#\9) {
  margin-right: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-l_18wu0_1xtkt_896:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-l_18wu0_1xtkt_887:not(#\9) {
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-l_18wu0_1xtkt_901:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-l_18wu0_1xtkt_892:not(#\9) {
  margin-left: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-xl_18wu0_1xtkt_906:not(#\9) {
  margin: var(--space-scaled-xl-w2t504, 24px) var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-xl_18wu0_1xtkt_910:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xl_18wu0_1xtkt_911:not(#\9) {
  margin-top: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-xl_18wu0_1xtkt_915:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xl_18wu0_1xtkt_916:not(#\9) {
  margin-right: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-xl_18wu0_1xtkt_920:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xl_18wu0_1xtkt_911:not(#\9) {
  margin-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-xl_18wu0_1xtkt_925:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xl_18wu0_1xtkt_916:not(#\9) {
  margin-left: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-xxl_18wu0_1xtkt_930:not(#\9) {
  margin: var(--space-scaled-xxl-pfgv96, 32px) var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-xxl_18wu0_1xtkt_934:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxl_18wu0_1xtkt_935:not(#\9) {
  margin-top: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-xxl_18wu0_1xtkt_939:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxl_18wu0_1xtkt_940:not(#\9) {
  margin-right: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-xxl_18wu0_1xtkt_944:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxl_18wu0_1xtkt_935:not(#\9) {
  margin-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-xxl_18wu0_1xtkt_949:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxl_18wu0_1xtkt_940:not(#\9) {
  margin-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-xxxl_18wu0_1xtkt_954:not(#\9) {
  margin: var(--space-scaled-xxxl-ffokcj, 40px) var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-top-xxxl_18wu0_1xtkt_958:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxxl_18wu0_1xtkt_959:not(#\9) {
  margin-top: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-right-xxxl_18wu0_1xtkt_963:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxxl_18wu0_1xtkt_964:not(#\9) {
  margin-right: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-bottom-xxxl_18wu0_1xtkt_968:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-vertical-xxxl_18wu0_1xtkt_959:not(#\9) {
  margin-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_m-left-xxxl_18wu0_1xtkt_973:not(#\9),
.awsui_box_18wu0_1xtkt_205.awsui_m-horizontal-xxxl_18wu0_1xtkt_964:not(#\9) {
  margin-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1xtkt_205.awsui_d-block_18wu0_1xtkt_978:not(#\9) {
  display: block;
}
.awsui_box_18wu0_1xtkt_205.awsui_d-inline_18wu0_1xtkt_981:not(#\9) {
  display: inline;
}
.awsui_box_18wu0_1xtkt_205.awsui_d-inline-block_18wu0_1xtkt_984:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_1xtkt_205.awsui_d-none_18wu0_1xtkt_987:not(#\9) {
  display: none;
}

.awsui_f-left_18wu0_1xtkt_991:not(#\9) {
  float: left;
}

.awsui_f-right_18wu0_1xtkt_995:not(#\9) {
  float: right;
}

/* stylelint-enable no-invalid-position-at-import-rule */