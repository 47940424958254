/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_2qdw9_1s542_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  cursor: inherit;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.awsui_root-no-actions_2qdw9_1s542_108.awsui_root-variant-h1_2qdw9_1s542_108:not(#\9) {
  padding-bottom: 0;
}
.awsui_root-no-actions_2qdw9_1s542_108.awsui_root-variant-h1-refresh_2qdw9_1s542_111:not(#\9) {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_root-no-actions_2qdw9_1s542_108.awsui_root-variant-h2_2qdw9_1s542_114:not(#\9), .awsui_root-has-description_2qdw9_1s542_114.awsui_root-variant-h2_2qdw9_1s542_114:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_root-no-actions_2qdw9_1s542_108.awsui_root-variant-h2-refresh_2qdw9_1s542_117:not(#\9), .awsui_root-has-description_2qdw9_1s542_114.awsui_root-variant-h2-refresh_2qdw9_1s542_117:not(#\9) {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_root-no-actions_2qdw9_1s542_108.awsui_root-variant-h3_2qdw9_1s542_120:not(#\9), .awsui_root-has-description_2qdw9_1s542_114.awsui_root-variant-h3_2qdw9_1s542_120:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_root-no-actions_2qdw9_1s542_108.awsui_root-variant-h3-refresh_2qdw9_1s542_123:not(#\9), .awsui_root-has-description_2qdw9_1s542_114.awsui_root-variant-h3-refresh_2qdw9_1s542_123:not(#\9) {
  padding-bottom: 0;
}
.awsui_root-no-wrap_2qdw9_1s542_126:not(#\9) {
  flex-wrap: nowrap;
}

.awsui_main_2qdw9_1s542_130:not(#\9) {
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
}
:not(#\9):not(.awsui_root-no-actions_2qdw9_1s542_108) > .awsui_main_2qdw9_1s542_130 {
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_main-variant-h1_2qdw9_1s542_138:not(#\9) {
  padding: var(--space-scaled-2x-xxs-t5qzmf, 4px) 0;
}
.awsui_main-variant-h1-refresh_2qdw9_1s542_141:not(#\9) {
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
:not(#\9):not(.awsui_root-no-actions_2qdw9_1s542_108) > .awsui_main-variant-h1-refresh_2qdw9_1s542_141 {
  padding-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}
.awsui_main-variant-h2_2qdw9_1s542_147:not(#\9), .awsui_main-variant-h3_2qdw9_1s542_147:not(#\9) {
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}
:not(#\9):not(.awsui_root-has-description_2qdw9_1s542_114) > .awsui_main-variant-h2_2qdw9_1s542_147, :not(#\9):not(.awsui_root-has-description_2qdw9_1s542_114) > .awsui_main-variant-h3_2qdw9_1s542_147 {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_main-variant-h2-refresh_2qdw9_1s542_153:not(#\9) {
  padding-top: 0;
}
:not(#\9):not(.awsui_root-has-description_2qdw9_1s542_114) > .awsui_main-variant-h2-refresh_2qdw9_1s542_153 {
  padding-bottom: 0;
}

.awsui_actions_2qdw9_1s542_160:not(#\9) {
  display: flex;
  align-items: flex-start;
}
.awsui_actions-variant-h1_2qdw9_1s542_164:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) 0;
}
.awsui_actions-variant-h1-refresh_2qdw9_1s542_167:not(#\9) {
  padding: var(--space-xs-rsr2qu, 8px) 0;
}
.awsui_actions-variant-h2_2qdw9_1s542_170:not(#\9), .awsui_actions-variant-h3_2qdw9_1s542_170:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) 0;
}
.awsui_root-has-description_2qdw9_1s542_114 > .awsui_actions-variant-h2_2qdw9_1s542_170:not(#\9), .awsui_root-has-description_2qdw9_1s542_114 > .awsui_actions-variant-h3_2qdw9_1s542_170:not(#\9) {
  padding-bottom: 0;
}
.awsui_actions-variant-h2-refresh_2qdw9_1s542_176:not(#\9) {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_actions-variant-h3-refresh_2qdw9_1s542_179:not(#\9) {
  padding-bottom: 0;
}

.awsui_title_2qdw9_1s542_183:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  color: var(--color-text-heading-default-jopz0f, #000716);
}
.awsui_title-variant-h2_2qdw9_1s542_189:not(#\9), .awsui_title-variant-h3_2qdw9_1s542_189:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) 0;
}
.awsui_title-variant-h2_2qdw9_1s542_189:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
}
.awsui_title-variant-h2-refresh_2qdw9_1s542_198:not(#\9) {
  padding-top: var(--space-scaled-2x-xxs-t5qzmf, 4px);
}
.awsui_title-variant-h3_2qdw9_1s542_189:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
}

.awsui_info_2qdw9_1s542_208:not(#\9) {
  padding-right: var(--space-s-hv8c1d, 12px);
}

.awsui_description_2qdw9_1s542_212:not(#\9) {
  margin: 0;
  padding: 0;
  color: var(--color-text-heading-secondary-corkaj, #414d5c);
}
.awsui_description-variant-h1_2qdw9_1s542_217:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_description-variant-h1-refresh_2qdw9_1s542_222:not(#\9) {
  padding-top: var(--space-scaled-xs-6859qs, 8px);
}
.awsui_description-variant-h2_2qdw9_1s542_225:not(#\9) {
  font-size: var(--font-header-h2-description-size-pqrrui, 14px);
  line-height: var(--font-header-h2-description-line-height-gjrb86, 22px);
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_description-variant-h3_2qdw9_1s542_230:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_description-variant-h3-refresh_2qdw9_1s542_236:not(#\9) {
  padding-top: var(--space-xxxs-k2w98v, 2px);
}

.awsui_heading_2qdw9_1s542_240:not(#\9) {
  margin: 0;
  display: inline;
  font-size: inherit;
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_heading_2qdw9_1s542_240:not(#\9):only-child {
  margin: 0;
}
.awsui_heading-variant-h1_2qdw9_1s542_249:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
  font-weight: var(--font-heading-xl-weight-772870, 800);
}
.awsui_heading-variant-h2_2qdw9_1s542_255:not(#\9) {
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
}
.awsui_heading-variant-h3_2qdw9_1s542_259:not(#\9) {
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
}

.awsui_heading-text_2qdw9_1s542_264:not(#\9) {
  /* used in test-utils */
}

.awsui_counter_2qdw9_1s542_268:not(#\9) {
  color: var(--color-text-counter-jwdegc, #5f6b7a);
  font-weight: 400;
}