/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_notifications-sticky_1hmm4_1qn9j_9:not(#\9) {
  top: 0;
  z-index: 825;
  position: sticky;
}